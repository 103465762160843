import { Component } from '@angular/core';
import { UserModel } from './models/userModel';
import { AuthService } from './services/auth.service';

@Component({
  selector: 'app',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent {
  user: UserModel;
  constructor(private authService: AuthService) {
    this.authService.user.subscribe((x) => (this.user = x));
  } 
}
