<footer class="sticky-footer bg-white">
  <div class="container my-auto">
    <div class="copyright text-center my-auto">
     
      <a href="#" target="_top"
        >{{footer1}}</a>
    </div>

    <div class="text-center mt-4">
      <div class="text-center">{{footer2}}</div>
     
    </div>
  </div>
</footer>

<!-- credits -->
