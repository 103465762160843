import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  test : Date = new Date();
  constructor() { }
  footer1 = environment.footer1;
  footer2= environment.footer2;
  ngOnInit(): void {
  }

}
