import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { DashboardModel } from 'src/app/models/dashboardModel';
import { AuthService } from 'src/app/services/auth.service';
import { TalepService } from 'src/app/services/talep.service';

import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css'],
})
export class DashboardComponent implements OnInit {
  constructor(
    private talepService: TalepService,
    private toastrService: ToastrService,
    private authService: AuthService
  ) {}
  dashboard: DashboardModel;
  dataLoaded = false;
  isontalepgoster = false;
  istalepgoster = false;
  isWebUserTamyetkili = false;
  ngOnInit(): void {
    this.isontalepgoster = environment.isontalepgoster;
    this.istalepgoster = environment.istalepgoster;
    this.GetDashBoard();
    
    var user = this.authService.userValue;
    this.isWebUserTamyetkili = user.ismusteriwebkullanicitamyetkili=="T" || user.ismusteriwebkullanicitamyetkili==null;
  }

  

  GetDashBoard() {
    this.talepService.GetDashBoard().subscribe((a) => {
      
      if (a.BasariliMi) {
        this.dashboard = a.Veri;
      } else {
        this.toastrService.info(a.Mesaj, 'Dikkat');
      }
      this.dataLoaded = true;
    });
  }
}
