import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { TalepDetayModel } from 'src/app/models/talepdetayModel';
import { YorumAddModel } from 'src/app/models/yorumAddModel';
import { TalepService } from 'src/app/services/talep.service';

@Component({
  selector: 'app-talepdetay',
  templateUrl: './talepdetay.component.html',
  styleUrls: ['./talepdetay.component.css'],
})
export class TalepdetayComponent implements OnInit {
  constructor(
    private talepService: TalepService,
    private toastrService: ToastrService,
    private formBuilder: UntypedFormBuilder,
    private activatedRoute: ActivatedRoute
  ) {}
  form: UntypedFormGroup;
  loading = false;
  dataLoaded = false;
  submitted = false;
  yorum: YorumAddModel;
  talep: TalepDetayModel;
  servisid :number;
  get f() {
    return this.form.controls;
  }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      aciklama: ['', Validators.required],
    });
    
    this.activatedRoute.params.subscribe((params) => {
      this.servisid=params['kayitid'];
      this.TalepList(params['kayitid']);
    });
  }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.form.invalid) {
      return;
    }
    this.loading = true;

    this.yorum = Object.assign({}, this.form.value);
    this.yorum.servisid= this.servisid;
 

    this.talepService.YorumAdd(this.yorum).subscribe(
      (res) => {
        if (res.BasariliMi) {
          window.location.reload();
          this.toastrService.info('Mesaj Kaydedildi.', 'Başarılı!');
     
        } else {
          this.toastrService.error(res.Mesaj, 'Dikkat!1');
          
        }
      
        this.loading = false;
      },
      (err) => {
        //console.log(err);
        this.toastrService.error(err.Mesaj, 'Dikkat!2');
        this.loading = false;
      }
    );
  }

  TalepList(kayitid: number) {
    this.talepService.getTalepDetay(kayitid).subscribe((a) => {
      console.log(a);
      if (a.BasariliMi) {
        this.talep = a.Veri;
      } else {
        this.toastrService.info(a.Mesaj, 'Dikkat');
      }
      this.dataLoaded = true;
    });
  }
}
