import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './components/auth/login/login/login.component';
import { ProfileComponent } from './components/auth/profile/profile/profile.component';
import { DashboardComponent } from './components/dashboard/dashboard/dashboard.component';
import { TalepComponent } from './components/talep/talep/talep.component';
import { TalepaddComponent } from './components/talepadd/talepadd/talepadd.component';
import { Talepadd2Component } from './components/talepadd2/talepadd2.component';
import { TalepdetayComponent } from './components/talepdetay/talepdetay/talepdetay.component';
import { LoginGuard } from './guards/login.guard';
 
const routes: Routes = [
  { path: '', pathMatch: 'full', component: DashboardComponent,  canActivate: [LoginGuard]},

  { path: 'dashboard',  component: DashboardComponent,  canActivate: [LoginGuard]},
  {
    path: "auth", children: [
      { path: "profile", component: ProfileComponent,canActivate: [LoginGuard] },
      { path: "login", component: LoginComponent },
    ]
  },
  
  { path: 'talep', component: TalepComponent, canActivate: [LoginGuard] },
  { path: 'talepadd', component: TalepaddComponent, canActivate: [LoginGuard] },
  { path: 'talepadd2', component: Talepadd2Component, canActivate: [LoginGuard] },
  { path: 'talepdetay/:kayitid', component: TalepdetayComponent, canActivate: [LoginGuard] },
  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes),],
  exports: [RouterModule],
})
export class AppRoutingModule {}
