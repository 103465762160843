<nav class="navbar navbar-expand-lg navbar-light bg-light">
  <a class="navbar-brand" href="#">{{ user.SirketAdi }}</a>
  <button
    class="navbar-toggler"
    type="button"
    data-toggle="collapse"
    data-target="#navbarSupportedContent"
    aria-controls="navbarSupportedContent"
    aria-expanded="false"
    aria-label="Toggle navigation"
  >
    <span class="navbar-toggler-icon"></span>
  </button>

  <div class="collapse navbar-collapse" id="navbarSupportedContent">
    <ul class="navbar-nav mr-auto">
      <li class="nav-item active">
        <a class="nav-link" href="#"
          >Dashboard <span class="sr-only">(current)</span></a
        >
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLink="/talep">Taleplerim</a>
      </li>
      <li class="nav-item"  *ngIf="isontalepgoster">
        <a class="nav-link" routerLink="/talepadd"> Ön Talep Oluştur</a>
      </li> 
      <li class="nav-item" *ngIf="istalepgoster">
        <a class="nav-link" routerLink="/talepadd2">Talep Oluştur</a>
      </li> 
      <li class="nav-item dropdown">
        <a
          class="nav-link dropdown-toggle"
          href="#"
          id="navbarDropdown"
          role="button"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          {{ user.adsoyad }}
        </a>
        <div class="dropdown-menu" aria-labelledby="navbarDropdown">
      
          <a class="dropdown-item" (click)="logout()">Çıkış</a>
        </div>
      </li>
    </ul>
   
  </div>
</nav>
